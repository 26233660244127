const imageExtensions = [
  'jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp',
];

const audioExtensions = [
  'wav', 'mp3', 'aif', 'aiff', 'aifc', 'wma', 'oga', 'm4a', 'flac',
];

const videoExtensions = [
  'mp4', 'mov', 'h264', 'wmv', '3gp', 'webm', 'ogv', 'ogg',
  'mpg', 'mpeg', 'mpe', 'avi', 'mkv', 'vob', 'm4v', 'mxf', 'm1v', 'm2v',
];

export const acceptedVisualSearchExtensions = [...imageExtensions, ...videoExtensions];
export const acceptedAuralSearchExtensions = [...audioExtensions, ...videoExtensions];

export const acceptedVisualSearchExtensionsFormats = ['.jpg', '.png', '.bmp', '.gif', '.mp4', '.mov', '.wmv', '.mpg'];
export const acceptedAuralSearchExtensionsFormats = ['.mp4', '.mov', '.avi', '.wmv', '.flv', '.3gp', '.wav', '.aif', '.mp3'];
