import { isProductType } from '@motionelements/core/src/services/catalog.service.js';
import { uploadToS3Temp } from '@motionelements/core/src/services/upload.service.js';

import notificationService from '@motionelements/core/src/services/notification.service.js';
import { getJobStatus } from '@motionelements/core/src/services/job.service.js';
import {
  acceptedAuralSearchExtensions,
  acceptedVisualSearchExtensions,
} from '@motionelements/core/src/helpers/acceptedFileExtensions.js';

import * as searchApi from '@motionelements/core/src/api/search.js';

export default {
  name: 'FileDropUpload',
  data() {
    return {
      pollInterval: null,
      isUploadDropzoneVisible: false,
      uploadLastTarget: null,
      errorMessage: null,
      cloudflareImageResizingExtensions: [
        // https://developers.cloudflare.com/images/image-resizing/format-limitations/
        'gif',
        'jpeg',
        'jpg',
        'png',
        'svg',
        'webp',
      ],
    };
  },
  computed: {
    mediaType() {
      return this.currentMediaType || this.channelType;
    },
    isAural() {
      return isProductType('audio', this.mediaType);
    },
    acceptedFileExtensions() {
      return this.isAural ? acceptedAuralSearchExtensions : acceptedVisualSearchExtensions;
    },
    pollingMessages() {
      if (this.isAural) {
        return [
          this.$i18n.t('search.analyzing_aural_1'),
          this.$i18n.t('search.analyzing_aural_2'),
          this.$i18n.t('search.analyzing_aural_3'),
          this.$i18n.t('search.analyzing_aural_4'),
          this.$i18n.t('search.analyzing_aural_5'),
          this.$i18n.t('search.analyzing_aural_6'),
        ];
      }

      return [
        this.$i18n.t('search.analyzing_visual_1'),
        this.$i18n.t('search.analyzing_visual_2'),
        this.$i18n.t('search.analyzing_visual_3'),
        this.$i18n.t('search.analyzing_visual_4'),
        this.$i18n.t('search.analyzing_visual_5'),
        this.$i18n.t('search.analyzing_visual_6'),
        this.$i18n.t('search.analyzing_visual_7'),
      ];
    },
    isFileSearchFeatureEnabled() {
      return isProductType('video', this.mediaType)
        || isProductType('image', this.mediaType)
        || isProductType('music', this.mediaType)
        || isProductType('video_template', this.mediaType);
    },
    isSearchPage() {
      return this.$route.name === 'view-search-list';
    },
  },
  mounted() {
    this.$root.$on('onDrop', (event) => {
      this.onDrop(event);
    });

    window.addEventListener('dragenter', this.onDragEnter);
    window.addEventListener('dragleave', this.onDragLeave);
    window.addEventListener('dragover', this.onDragOver);
    window.addEventListener('drop', this.onDrop);
  },
  beforeDestroy() {
    window.removeEventListener('dragenter', this.onDragEnter);
    window.removeEventListener('dragleave', this.onDragLeave);
    window.removeEventListener('dragover', this.onDragOver);
    window.removeEventListener('drop', this.onDrop);
    clearInterval(this.pollInterval);
    this.$store.commit('searchBox/setUploadProgress', {
      status: false,
      message: this.$t('search.analyze_done'),
      progress: 0,
    });
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(`visual-search-modal-${this.mediaType}`);
      this.$root.$emit('bv::toggle::collapse', `visual-search-sidebar-${this.mediaType}`);
    },
    onDragEnter(e) {
      if (!this.isFileSearchFeatureEnabled) {
        return;
      }

      this.uploadLastTarget = e.target;
      this.isUploadDropzoneVisible = true;
    },
    onDragLeave(e) {
      if (!this.isFileSearchFeatureEnabled) {
        return;
      }

      if (e.target === this.uploadLastTarget) {
        this.isUploadDropzoneVisible = false;
      }
    },
    onDragOver(e) {
      e.preventDefault();
    },
    buildCloudflareImageUrl(url) {
      const width = 320;
      const height = 320;
      return `https://motionelements.com/cdn-cgi/image/w=${width},h=${height},q=50,format=baseline-jpeg,metadata=none/${url}`;
    },
    onDrop(e) {
      e.preventDefault();

      if (!this.isFileSearchFeatureEnabled) {
        return;
      }

      this.isUploadDropzoneVisible = false;
      const files = e.target.files || e.dataTransfer.files;
      console.log('ondrop files ', files);
      if (!files.length) {
        return;
      }

      this.$scrollToTop();

      const formData = new FormData();
      const hasFile = _.isObject(files[0]);
      console.log(hasFile);
      if (hasFile) {
        const file = files[0];
        const fileName = files[0].name;
        const originalFilename = files[0].name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        const randomName = this.$uuid();
        const uploadFilename = `${randomName}.${fileExtension}`;
        // const uploadFilename = sanitize(originalFilename);
        // console.log(fileExtensions);

        const isAcceptedFormat = this.acceptedFileExtensions.includes(fileExtension);

        const searchMode = this.isAural ? 'aural' : 'visual';

        if (!isAcceptedFormat) {
          notificationService.alert({
            level: 'warning',
            title: this.$i18n.t('search.not_supported_media'),
          });
        } else {
          // set progress bar ON
          this.$store.commit('searchBox/setUploadProgress', {
            status: true,
            message: this.isAural ? this.$t('search.analyzing_audio') : this.$t('search.analyzing_image'),
            progress: 0,
          });

          uploadToS3Temp('search', file, uploadFilename, {
            onUploadProgress: progressEvent => {
              // trigger upload progress block when doing upload
              this.$store.commit('searchBox/setUploadProgress', {
                status: true,
                progress: Math.round((progressEvent.loaded / progressEvent.total) * 100),
              });
            },
          }).then((response) => {
            const fileUrl = response.url;

            // if extension is image, can search directly via cloudflare worker image resize
            if (this.cloudflareImageResizingExtensions.includes(fileExtension)) {
              this.onAnalyzeSuccess({
                url: this.buildCloudflareImageUrl(fileUrl),
              }, originalFilename);
              return;
            }

            this.analyzeFile({
              type: searchMode,
              url: fileUrl,
            }).then((response) => {
              const data = response.data.data;

              // check if status is completed, if it is stop polling
              if (data.status === 'succeeded') {
                this.onAnalyzeSuccess(data, originalFilename);
                return;
              }

              const jobId = _.get(data, 'job.id');

              if (jobId) {
                return this.pollJobStatus(jobId, {
                  type: searchMode,
                  url: fileUrl,
                  originalFilename: originalFilename,
                });
              }

              throw new Error('failed to create job.');
            }).catch((error) => {
              notificationService.alert({
                level: 'danger',
                title: _.get(error.response, 'data.errors[0].title') || this.$i18n.t('error.failed'),
              });
              this.onFail();
            });
          });
        }
      }
      this.$emit('drop', formData, files);
    },
    onFail(errorMessage) {
      // clearInterval(this.pollInterval);
      this.errorMessage = errorMessage || 'failed';
      this.$store.commit('searchBox/setUploadProgress', {
        status: false,
        message: this.$t('search.analyze_done'),
        progress: 0,
      });
    },
    onAnalyzeSuccess(data, originalFilename) {
      // set progress bar OFF
      this.$store.commit('searchBox/setUploadProgress', {
        status: false,
        message: this.$t('search.analyze_done'),
        progress: 0,
      });

      if (this.isSearchPage) {
        this.closeModal();

        const query = {
          ...this.$route.query,
          // page: 1,
        };

        if (this.isAural) {
          query.similar = `${data.externalTrackId}-${originalFilename}`;
        } else {
          query.similar = data.url;
        }

        // set page 1
        delete query.page;

        this.$router.push({
          query: query,
        });

        return;
      }

      if (this.isAural) {
        window.location.href = this.$link(`/search/${this.mediaType}`).param('similar', `${data.externalTrackId}-${originalFilename}`).url();
      } else {
        window.location.href = this.$link(`/search/${this.mediaType}`).param('sort', 'match').param('similar', data.url).url();
      }
    },
    analyzeFile(params) {
      this.$store.commit('searchBox/setUploadProgress', {
        status: true,
        message: _.sample(this.pollingMessages),
        progress: 0,
      });

      return searchApi[this.isAural ? 'analyzeAuralFeature' : 'analyzeVisualFeature'](params);
    },
    pollJobStatus(jobId, params) {
      // return this.$store.dispatch('artistElementEdit/getJobStatus', jobId)
      getJobStatus(jobId).then(response => response.data.data)
        .then(({ status, message }) => {
          console.log('got status', status);
          // status: pending, running, done, failed
          this.$emit('status-update', status);

          switch (status) {
            case 'done':
              console.log('DONE', params);
              // get result
              searchApi[this.isAural ? 'getAnalyzeAuralFeatureResult' : 'getAnalyzeVisualFeatureResult'](params)
                .then(response => {
                  console.log('DONE response', response);
                  const data = response.data.data;
                  // check if status is completed, if it is stop polling
                  if (data.status === 'succeeded') {
                    // clearInterval(this.pollInterval);
                    this.onAnalyzeSuccess(data, params.originalFilename);
                  } else {
                    this.onFail();
                    notificationService.alert({
                      level: 'danger',
                      title: this.$i18n.t('error.failed'),
                    });
                    // console.log('ERROR?');
                  }
                })
                .catch((error) => {
                  console.log(_.get(error.response, 'data.errors[0].title') || this.$i18n.t('error.failed'));
                  this.onFail();
                });

              break;
            case 'failed':
              this.onFail(message);
              break;
            case 'pending':
            case 'running':
            default:
              this.pollJobStatus(jobId, params);
              break;
          }
        });
    },
  },
};
