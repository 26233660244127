export const faUserVneck = {
  prefix: 'fas',
  iconName: 'me-user-vneck',
  icon: [448, 512, [], 'e93e', 'M96 128C96 57.31 153.3 0 224 0C294.7 0 352 57.31 352 128C352 198.7 294.7 256 224 256C153.3 256 96 198.7 96 128zM320 128C320 74.98 277 32 224 32C170.1 32 128 74.98 128 128C128 181 170.1 224 224 224C277 224 320 181 320 128zM448 479.1C448 497.7 433.7 511.1 416 511.1H32C14.33 511.1 0 497.7 0 479.1V471.1C0 388.2 61.33 318.8 141.6 306.1L224 416L306.4 306.1C386.7 318.8 448 388.2 448 471.1V479.1zM128.4 341.8C72.6 358.8 32 410.7 32 471.1V479.1H416V471.1C416 410.7 375.4 358.8 319.6 341.8L249.6 435.2C243.6 443.3 234.1 448 224 448C213.9 448 204.4 443.3 198.4 435.2L128.4 341.8z'],
};

export const faBuildings = {
  prefix: 'fas',
  iconName: 'me-buildings',
  icon: [512, 512, [], 'e93e', 'M320 344C320 330.7 330.7 320 344 320H392C405.3 320 416 330.7 416 344V392C416 405.3 405.3 416 392 416H344C330.7 416 320 405.3 320 392V344zM384 384V352H352V384H384zM320 88C320 74.75 330.7 64 344 64H392C405.3 64 416 74.75 416 88V136C416 149.3 405.3 160 392 160H344C330.7 160 320 149.3 320 136V88zM352 96V128H384V96H352zM344 288C330.7 288 320 277.3 320 264V216C320 202.7 330.7 192 344 192H392C405.3 192 416 202.7 416 216V264C416 277.3 405.3 288 392 288H344zM352 224V256H384V224H352zM448 0C483.3 0 512 28.65 512 64V448C512 483.3 483.3 512 448 512H288C252.7 512 224 483.3 224 448V64C224 28.65 252.7 0 288 0H448zM448 32H288C270.3 32 256 46.33 256 64V448C256 465.7 270.3 480 288 480H448C465.7 480 480 465.7 480 448V64C480 46.33 465.7 32 448 32zM176 160H64C46.33 160 32 174.3 32 192V448C32 465.7 46.33 480 64 480H192C200.8 480 208 487.2 208 496C208 504.8 200.8 512 192 512H64C28.65 512 0 483.3 0 448V192C0 156.7 28.65 128 64 128H176C184.8 128 192 135.2 192 144C192 152.8 184.8 160 176 160zM136 320C149.3 320 160 330.7 160 344V392C160 405.3 149.3 416 136 416H88C74.75 416 64 405.3 64 392V344C64 330.7 74.75 320 88 320H136zM128 384V352H96V384H128zM136 192C149.3 192 160 202.7 160 216V264C160 277.3 149.3 288 136 288H88C74.75 288 64 277.3 64 264V216C64 202.7 74.75 192 88 192H136zM128 256V224H96V256H128z'],
};

export const faHouse = {
  prefix: 'fas',
  iconName: 'me-house',
  icon: [576, 512, [], 'e93e', 'M567.5 229.7C577.6 238.3 578.9 253.4 570.3 263.5C561.7 273.6 546.6 274.9 536.5 266.3L512 245.5V432C512 476.2 476.2 512 432 512H144C99.82 512 64 476.2 64 432V245.5L39.53 266.3C29.42 274.9 14.28 273.6 5.7 263.5C-2.875 253.4-1.634 238.3 8.473 229.7L272.5 5.7C281.4-1.9 294.6-1.9 303.5 5.7L567.5 229.7zM144 464H192V312C192 289.9 209.9 272 232 272H344C366.1 272 384 289.9 384 312V464H432C449.7 464 464 449.7 464 432V204.8L288 55.47L112 204.8V432C112 449.7 126.3 464 144 464V464zM240 464H336V320H240V464z'],
};

export const faShoppingCart = {
  prefix: 'fas',
  iconName: 'me-cart-shopping',
  icon: [576, 512, [], 'e93e', 'M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM475.6 240L520.6 80H131.1L161.6 240H475.6zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z'],
};

export const faUser = {
  prefix: 'fas',
  iconName: 'me-user',
  icon: [448, 512, [], 'e93e', 'M272 304h-96C78.8 304 0 382.8 0 480c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32C448 382.8 369.2 304 272 304zM48.99 464C56.89 400.9 110.8 352 176 352h96c65.16 0 119.1 48.95 127 112H48.99zM224 256c70.69 0 128-57.31 128-128c0-70.69-57.31-128-128-128S96 57.31 96 128C96 198.7 153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80S144 172.1 144 128C144 83.89 179.9 48 224 48z'],
};

export const faXMark = {
  prefix: 'fas',
  iconName: 'me-x-mark',
  icon: [320, 512, [], 'e93e', 'M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z'],
};

export const faCloudArrowUp = {
  prefix: 'fas',
  iconName: 'me-cloud-arrowU-up',
  icon: [640, 512, [], 'e93e', 'M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z'],
};

export const faGrid2 = {
  prefix: 'fas',
  iconName: 'me-grid-2',
  icon: [448, 512, [], 'e93e', 'M192 80c0-26.5-21.5-48-48-48H48C21.5 32 0 53.5 0 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80zm0 256c0-26.5-21.5-48-48-48H48c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336zM256 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H304c-26.5 0-48 21.5-48 48zM448 336c0-26.5-21.5-48-48-48H304c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336z'],
};

export const faCircleExclamation = {
  prefix: 'far',
  iconName: 'me-circle-exclamation',
  icon: [512, 512, [], 'f06a', 'M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 304c13.25 0 24-10.75 24-24v-128C280 138.8 269.3 128 256 128S232 138.8 232 152v128C232 293.3 242.8 304 256 304zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 385.9 238.6 400 256 400s31.44-14.08 31.44-31.44C287.4 351.2 273.4 337.1 256 337.1z'],
};

export const faLoader = {
  prefix: 'far',
  iconName: 'me-loader',
  icon: [576, 512, [], 'f06a', 'M312 104C312 117.3 301.3 128 288 128C274.7 128 264 117.3 264 104V24C264 10.75 274.7 0 288 0C301.3 0 312 10.75 312 24V104zM312 488C312 501.3 301.3 512 288 512C274.7 512 264 501.3 264 488V408C264 394.7 274.7 384 288 384C301.3 384 312 394.7 312 408V488zM32 256C32 242.7 42.75 232 56 232H136C149.3 232 160 242.7 160 256C160 269.3 149.3 280 136 280H56C42.75 280 32 269.3 32 256zM520 232C533.3 232 544 242.7 544 256C544 269.3 533.3 280 520 280H440C426.7 280 416 269.3 416 256C416 242.7 426.7 232 440 232H520zM469 74.98C478.4 84.35 478.4 99.55 469 108.9L412.5 165.5C403.1 174.9 387.9 174.9 378.5 165.5C369.1 156.1 369.1 140.9 378.5 131.6L435.1 74.98C444.5 65.61 459.6 65.61 469 74.98zM140.9 437C131.5 446.4 116.4 446.4 106.1 437C97.61 427.6 97.61 412.4 106.1 403.1L163.5 346.5C172.9 337.1 188.1 337.1 197.5 346.5C206.9 355.9 206.9 371.1 197.5 380.4L140.9 437zM106.1 74.98C116.4 65.61 131.5 65.61 140.9 74.98L197.5 131.6C206.9 140.9 206.9 156.1 197.5 165.5C188.1 174.9 172.9 174.9 163.5 165.5L106.1 108.9C97.61 99.55 97.61 84.35 106.1 74.98V74.98zM469 403.1C478.4 412.4 478.4 427.6 469 437C459.6 446.4 444.5 446.4 435.1 437L378.5 380.4C369.1 371.1 369.1 355.9 378.5 346.5C387.9 337.1 403.1 337.1 412.5 346.5L469 403.1z'],
};

export const faSwatchbook = {
  prefix: 'fas',
  iconName: 'me-swatchbook',
  icon: [512, 512, [], 'e93e', 'M72 416C72 402.7 82.75 392 96 392C109.3 392 120 402.7 120 416C120 429.3 109.3 440 96 440C82.75 440 72 429.3 72 416zM0 56C0 25.07 25.07 0 56 0H136C166.9 0 192 25.07 192 56V188.2L283.2 96.97C305.1 75.1 340.6 75.1 362.4 96.97L419 153.5C440.9 175.4 440.9 210.9 419 232.7L331.7 320H456C486.9 320 512 345.1 512 376V456C512 486.9 486.9 512 456 512H96C42.98 512 0 469 0 416V56zM480 456V376C480 362.7 469.3 352 456 352H299.7L171.7 480H456C469.3 480 480 469.3 480 456zM396.4 176.2L339.8 119.6C330.4 110.2 315.2 110.2 305.9 119.6L192 233.5V414.5L396.4 210.1C405.8 200.7 405.8 185.5 396.4 176.2V176.2zM96 480C131.3 480 160 451.3 160 416V288H32V416C32 451.3 60.65 480 96 480zM32 256H160V160H32V256zM32 128H160V56C160 42.75 149.3 32 136 32H56C42.75 32 32 42.75 32 56V128z'],
};

export const faMeCameraRetro = {
  prefix: 'fas',
  iconName: 'me-camera-retro',
  icon: [40, 36, [], 'e93e', 'M2.5 2.53125C2.5 2.84375 2.65625 3 2.96875 3H12.0312C12.2656 3 12.5 2.84375 12.5 2.53125V0.96875C12.5 0.734375 12.2656 0.5 12.0312 0.5H2.96875C2.65625 0.5 2.5 0.734375 2.5 0.96875V2.53125ZM29.375 20.5C29.375 15.3438 25.1562 11.125 20 11.125C14.7656 11.125 10.625 15.3438 10.625 20.5C10.625 25.7344 14.7656 29.875 20 29.875C25.1562 29.875 29.375 25.7344 29.375 20.5ZM26.875 20.5C26.875 24.3281 23.75 27.375 20 27.375C16.1719 27.375 13.125 24.3281 13.125 20.5C13.125 16.75 16.1719 13.625 20 13.625C23.75 13.625 26.875 16.75 26.875 20.5ZM17.5 20.5C17.5 19.1719 18.5938 18 20 18C20.625 18 21.25 17.4531 21.25 16.75C21.25 16.125 20.625 15.5 20 15.5C17.1875 15.5 15 17.7656 15 20.5C15 21.2031 15.5469 21.75 16.25 21.75C16.875 21.75 17.5 21.2031 17.5 20.5ZM40 4.25C40 2.21875 38.2812 0.5 36.25 0.5H20C18.75 0.5 17.6562 1.125 16.9531 2.0625L14.375 5.5H3.75C1.64062 5.5 0 7.21875 0 9.25V31.75C0 33.8594 1.64062 35.5 3.75 35.5H36.25C38.2812 35.5 40 33.8594 40 31.75V4.25ZM17.5 5.5L18.9844 3.54688C19.2188 3.23438 19.6094 3 20 3H36.25C36.875 3 37.5 3.625 37.5 4.25V5.73438C37.1094 5.57812 36.6406 5.5 36.25 5.5H17.5ZM37.5 9.25V31.75C37.5 32.4531 36.875 33 36.25 33H3.75C3.04688 33 2.5 32.4531 2.5 31.75V9.25C2.5 8.625 3.04688 8 3.75 8H36.25C36.875 8 37.5 8.625 37.5 9.25Z'],
};
