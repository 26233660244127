/* eslint-disable import/prefer-default-export */
import api from './base-api-v2';

export const getJobStatus = async (id, axiosConfig) => api.request('get', `/v2/jobs/${id}`, {
  ...(axiosConfig || {}),
});

// export const getArtistElementJobs = (params) => api.get('/v2/artist/jobs', {
//   params,
// });

export const listArtistJobs = (params) => api.get('/v2/artist/jobs', {
  params,
});
