//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { faHeadphonesAlt } from '@fortawesome/pro-regular-svg-icons';

import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';
import {
  acceptedVisualSearchExtensionsFormats,
  acceptedAuralSearchExtensionsFormats,
} from '@motionelements/core/src/helpers/acceptedFileExtensions.js';
import fileSearchMixin from '@motionelements/core/src/components/mixins/file-search.mixin.js';
import { faMeCameraRetro } from '@motionelements/assets/src/js/me-font/icons-fa6.js';

export default {
  name: 'FileSearch',
  mixins: [fileSearchMixin, featureFlagMixin],
  props: {
    channelType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      faMeCameraRetro,
      faHeadphonesAlt,
    };
  },
  computed: {
    ...mapState({
      isLoadingProgress: state => state.searchBox.upload.status,
      loadingProgressMessage: state => state.searchBox.upload.message,
      loadingProgress: state => state.searchBox.upload.progress,
    }),
    acceptedFileExtensionsWithDot() {
      return this.isAural ? acceptedAuralSearchExtensionsFormats : acceptedVisualSearchExtensionsFormats;
    },
  },
  mounted() {
    this.$root.$on('onDrop', (event) => {
      this.onDrop(event);
    });
  },
  methods: {
    onClickUpload() {
      this.$refs.fileUpload.click();
    },
  },
};
